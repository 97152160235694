import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory} from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import companyLogo from '../../../worksider-logo.png';
import PortalStyle from '../../../assets/style/portalStyle.js';
import linkedinIcon from '../../../assets/images/linkedinIcon.svg';
import instagramIcon from '../../../assets/images/instagramIcon.svg';
import telegramIcon from '../../../assets/images/telegramIcon.svg';
import memberNumber from '../../../memberNumber';


class PortalFooter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            home: ""
        };

    }

    componentDidMount(){
        //this.loadLogo();
    }

    render() {
        const { classes } = this.props;

        return (
            <footer
                position="static"
                style={{
                    backgroundColor: 'rgb(0,29,56)',
                    boxShadow: 'none',
                    paddingTop: '5px',
                    paddingLeft: '5%',
                    paddingRight: '5%',
                    marginTop: "100px",
                    paddingBottom:"50px",
                }}
            >

            <Grid container direction="row" justify="flex-start">
                <Grid item className={classes.footer_grid_left}>
                    <img src={companyLogo} alt="" style={{maxWidth: "200px"}}/>
                    <Typography variant="body1" className={classes.banner_text_footer} style={{marginTop: "30px", maxWidth:"450px"}}>
                        Worksider helps companies find interns with ease. 
                        The internship postings are directly posted in Singapore Internship Opportunities Telegram Channel (@sginternships) 
                        where it reaches {memberNumber}+ students on their devices instantly. In 5mins, you can expose your opportunity to thousands of students.
                    </Typography>
                    <Typography variant="body1" className={classes.banner_text_footer} style={{marginTop: "30px"}}>
                        Contact us at: <br></br>
                        team@worksider.com 
                    </Typography>    
                    <div style={{marginTop: "10px"}}>
                        <a href="https://www.linkedin.com/company/worksider/" target="_blank" style={{marginRight: "10px"}}>
                            <img src={linkedinIcon} alt="" style={{maxWidth: "30px"}}/>
                        </a>
                        <a href="https://www.instagram.com/worksider/" target="_blank" style={{marginRight: "10px"}}>
                            <img src={instagramIcon} alt="" style={{maxWidth: "30px"}}/>
                        </a>

                        <a href="https://t.me/sginternships" target="_blank" >
                            <img src={telegramIcon} alt="" style={{maxWidth: "30px"}}/>
                        </a>

                    </div>

                    <Typography variant="caption" className={classes.banner_text_footer} style={{marginTop: "10px"}}>
                        &copy; 2020 Worksider.com
                    </Typography>    
                </Grid>
           
                <Grid item className={classes.footer_grid}>
                    <Typography variant="h5" component="h3"  className={classes.banner_text_footer}>
                        Company
                    </Typography>

                    <div className={classes.banner_underline} onClick={() => {browserHistory.push("/app/about")}}>
                        <Typography variant="body2" className={classes.banner_text_grey} style={{marginTop:"10px"}}>
                                About
                        </Typography>
                    </div>
                    <div className={classes.banner_underline} onClick={() => {browserHistory.push("/app"); location.href = location.href}}>
                        <Typography variant="body2" className={classes.banner_text_grey} style={{marginTop:"10px"}}>
                            Explore Jobs
                        </Typography>
                    </div>
                    <div className={classes.banner_underline} onClick={() => {browserHistory.push("/app/addJob")}}>
                        <Typography variant="body2" className={classes.banner_text_grey} style={{marginTop:"10px"}}>
                            Post a Job
                        </Typography>
                    </div>
                    <div className={classes.banner_underline} onClick={() => {browserHistory.push("/app/career-services")}}>
                        <Typography variant="body2" className={classes.banner_text_grey} style={{marginTop:"10px"}}>
                            Career Services
                        </Typography>
                    </div>
                    <div className={classes.banner_underline} onClick={() => {browserHistory.push("/app/contactUs")}}>
                        <Typography variant="body2" className={classes.banner_text_grey} style={{marginTop:"10px"}}>
                            Contact Us
                        </Typography>
                    </div>

                </Grid>
       
            </Grid>
        

            </footer>
        );
    }
}

PortalFooter.propTypes = {
    classes: PropTypes.object.isRequired,
};

PortalFooter.defaultProps = {};

export default withStyles(PortalStyle)(PortalFooter);


