import axios from 'axios';
import returnEnvironment from '../../environmentVariable.js';

/**
 * API calling module
 * @param  {Object} httpObj          [HTTP confirguration object]
 * @param  {Function} successHandler [Success Callback]
 * @param  {Function} errorHandler   [Failure Callback]
 * @return {Objet Callback}          [Return Object Callback]
 */
export default function requestData(httpObj, successHandler, errorHandler, isJSON = true) {
    const serverUrl = returnEnvironment();
    const paramsInfo = httpObj.params;
    let httpData = JSON.stringify(httpObj.data);
    
    if (!isJSON) {
        httpData = httpObj.data;
    }

    //console.log(httpObj.url);
    //console.log(serverUrl);
    //console.log(httpObj.baseURL);
   
    return axios.request({
        url: httpObj.url,
        method: httpObj.method || 'post',
        baseURL: httpObj.baseURL || serverUrl,
        headers: {
            ...{
                'Content-Type': 'application/json',
            },
            ...httpObj.headers,
        },
        params: paramsInfo,
        timeout: 40000,
        data: httpData,
        responseType: httpObj.responseType || "",
        withCredentials: true
    }).then(res => {
        //console.log(res);
        return res;
    })
        .then(successHandler, errorHandler);
}
