/**
 * This is the styling file for PortalHeader component.
 */

const cssInfo = {

    fullMenu:{
        ['@media (min-width: 1050px)']: { 
            display: "contents !important",
        },
    },

    banner_btn:{
        "& :hover": {
            backgroundColor: "RGB(0, 211, 99,0.5) !important",
        }
    },
    
    banner_btn_login:{
        border: '1px white solid',
        backgroundColor: 'RGB(3,123,230) !important',
        margin: '0 0 0 7px',
        padding: '5px',

        "& :hover": {
            backgroundColor: "RGB(0, 0, 0, 0.2) !important",  
        }
    },

    banner_btn_logout:{
        border: '1px white solid',
        backgroundColor: 'darkgrey !important',
        margin: '0 0 0 7px',
        padding: '5px',

        "& :hover": {
            backgroundColor: "RGB(0, 0, 0, 0.2) !important",  
        }
    },

    banner_text: {
        color: 'black',
        fontWeight: '600', 
        fontSize: "0.75rem",
   
    },

    banner_text_drawer: {
        color: 'white',
        fontWeight: '500', 
        ['@media (max-width: 1050px)']: { 
            fontSize:"1.1rem"
        },
   
    },


    banner_text_footer: {
        color: 'white',
        fontWeight: '500', 
    },

    banner_text_grey: {
        color: 'rgb(170,177,183)',
        fontWeight: '500', 
        ['@media (max-width: 450px)']: { 
            fontSize:"0.9rem"
        },
   
    },

    banner_underline: {
        cursor: 'pointer',
        alignSelf: "center",
        "& :hover": {
            textDecoration: "underline !important",
        }
    },

    banner_underline_drawer: {
        cursor: 'pointer',
        "& :hover": {
            textDecoration: "underline !important",
        }
    },

    drawer: {
        /* width: 0%;*/
        flexShrink: "0",

        ['@media (min-width: 1050px)']: { 
            display:"none",
        },
    },
 
    drawer_paper: {
         /*width: 0%;*/
         transform: "translateX(0)",
         backgroundColor: "rgb(3, 123, 230) !important",

        ['@media (min-width: 1050px)']: { 
            display:"none",
        },
    },

    menu_icon: {
        ['@media (max-width: 1049px)']: { 
            display: "block !important",
        },
    },


    typography_format: {
        color:"white",
    },

    sns_icon_outer: {
        fontSize: "15px",
        width: "40px",
        height: "40px",
        display: "inline-block",
        textAlign: "center",
        background: "transparent",
        borderRadius: "50%",
        lineHeight: "40px !important",
        marginRight: "7px",
        color: "#fff !important",
        transition: "0.3s",
        border: "1px solid #334A60",
    },

    footer_grid: {
        padding: "10px",
    },

    footer_grid_left: {
        padding: "10px",
        ['@media (min-width: 1050px)']: { 
            marginRight:"100px",
        },
    },

    header_icons: {
        color: "white",
        marginLeft: "inherit"
    },
};

export default cssInfo;
