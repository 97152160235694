import React, {Suspense, lazy, Component} from 'react';
import {browserHistory, Route, Router, IndexRedirect} from 'react-router';
import {render} from 'react-dom';
import {createMuiTheme} from '@material-ui/core/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Spinner from 'react-spinkit';
import CssBaseline from '@material-ui/core/CssBaseline';
import PortalHeader from './js/components/portal/PortalHeader.jsx';
import PortalFooter from './js/components/portal/PortalFooter.jsx';
import './assets/style/portalStyle'
import defaultLogo from "./js/components/job-list/default_logo.png";
import httpHelper from './js/helpers/httpHelper';
import returnEnvironment from './environmentVariable.js';
import ReactGA from 'react-ga';

const JobList = lazy(() => import('./js/components/job-list/JobList.jsx'));
const ChangePassword = lazy(() => import('./js/components/accounts/ChangePassword.jsx'));
const ResetPassword = lazy(() => import('./js/components/accounts/ResetPassword.jsx'));
const JobDetails = lazy(() => import('./js/components/job-list/JobDetails.jsx'));
const Login = lazy(() => import('./js/components/accounts/Login.jsx'));
const CreateAccount = lazy(() => import('./js/components/accounts/CreateAccount.jsx'));
const AddEditJob = lazy(() => import('./js/components/employer/AddEditJob.jsx'));
const Dashboard = lazy(() => import('./js/components/employer/Dashboard.jsx'));
const CompanyProfile = lazy(() => import('./js/components/employer/CompanyProfile.jsx'));
const About = lazy(() => import('./js/components/portal/About.jsx'));
const Contact = lazy(() => import('./js/components/portal/Contact.jsx'));
const PostConfirmation = lazy(() => import('./js/components/employer/PostConfirmation.jsx'));
const PaymentPage = lazy(() => import('./js/components/payment/PaymentPage.jsx'));
const Cancel = lazy(() => import('./js/components/payment/Cancel.jsx'));
const Success = lazy(() => import('./js/components/payment/Success.jsx'));
const PageNotFound = lazy(() => import('./js/components/portal/PageNotFound.jsx'));
const ApplicationConfirmation = lazy(() => import('./js/components/applicants/ApplicationConfirmation.jsx'));

//temp for career services.
const CareerServices = lazy(() => import('./js/components/career-services(temp)/CareerServices.jsx'));
const CareerServicesItemDetails = lazy(() => import('./js/components/career-services(temp)/CareerServicesItemDetails.jsx'));
const CareerServicesConfirmation = lazy(() => import('./js/components/career-services(temp)/CareerServicesConfirmation.jsx'));

export default class JobPortal extends Component {
    constructor(props) {
        super(props);
        //session storage for login variables
        const savedLogin = JSON.parse(sessionStorage.getItem('isLoggedIn'));
        const savedClient = JSON.parse(sessionStorage.getItem('client_uid'));
        const savedLogo = JSON.parse(sessionStorage.getItem('logoUrl'));

        this.state = {
            targetJob: null,
            uid: savedClient || 0, //state variable passed to children.
            isLoggedIn: savedLogin || false, //state variable passed to children.
            openDrawer: false,
            logoUrl: savedLogo || defaultLogo,
            currentPageNo: 1,
            employer_first_name: "",
            careerServicesId: "", //temp for career services
        };

        this.createTheme = this.createTheme.bind(this);
        this.getTargetJob = this.getTargetJob.bind(this);
        this.viewTargetJob = this.viewTargetJob.bind(this);
        this.viewTargetJobWithName = this.viewTargetJobWithName.bind(this);
        this.loginSuccess = this.loginSuccess.bind(this);
        this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
        this.handleDrawerClose = this.handleDrawerClose.bind(this);
        this.updateProfile = this.updateProfile.bind(this);
        this.loadLogo = this.loadLogo.bind(this);
        this.logoSuccess = this.logoSuccess.bind(this);
        this.logoFailure = this.logoFailure.bind(this);
        this.checkSession = this.checkSession.bind(this);
        this.sessionFailure = this.sessionFailure.bind(this);
        this.sessionSuccess = this.sessionSuccess.bind(this);
        this.savePageNo = this.savePageNo.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.loadProfileSuccess = this.loadProfileSuccess.bind(this);
        this.loadProfileFailure = this.loadProfileFailure.bind(this);
        this.routerUpdate = this.routerUpdate.bind(this);

        this.viewCareerServicesDetails = this.viewCareerServicesDetails.bind(this);

        this.routes = (
            <div>
                <Route
                    path="/"
                    component={() => <JobList uid={this.state.uid}
                                              editTargetJob={this.getTargetJob}
                                              viewTargetJob={this.viewTargetJob} 
                                              viewTargetJobWithName = {this.viewTargetJobWithName}
                                              savePageNo = {this.savePageNo}
                                              currentPageNo = {this.state.currentPageNo}
                                              />}
                />

                <Route
                    path="/app"
                    component={() => <JobList uid={this.state.uid}
                                              editTargetJob={this.getTargetJob}
                                              viewTargetJob={this.viewTargetJob} 
                                              viewTargetJobWithName = {this.viewTargetJobWithName}
                                              savePageNo = {this.savePageNo}
                                              currentPageNo = {this.state.currentPageNo}
                                              />}
                />

                <Route
                    path="/app/jobs"
                    component={() => <JobList uid={this.state.uid}
                                              editTargetJob={this.getTargetJob} 
                                              viewTargetJob={this.viewTargetJob} 
                                              viewTargetJobWithName = {this.viewTargetJobWithName}
                                              //viewTargetJobWithNamePublic = {this.viewTargetJobWithNamePublic}
                                              savePageNo = {this.savePageNo} 
                                              currentPageNo = {this.state.currentPageNo} />}
                />

                <Route
                    path="/app/login"
                    component={(props) =><Login {...props} loginSuccess={this.loginSuccess}  uid={this.state.uid}/>}
                />

                {/* Should deprecate */}
                <Route
                    path="/app/login/fromEmail/:emailJobCode"
                    component={(props) => <Login {...props} loginSuccess={this.loginSuccess}  uid={this.state.uid}/>}
                />

                <Route
                    path="/app/login/redirect/:emailJobCode"
                    component={(props) => <Login {...props} loginSuccess={this.loginSuccess}  uid={this.state.uid}/>}
                />


                <Route
                    path="/app/createAccount"
                    component={() => <CreateAccount loginSuccess={this.loginSuccess} isLoggedIn = {this.state.isLoggedIn}/>}
                />

                <Route
                    path="/app/resetPassword"
                    component={() => <ResetPassword uid={this.state.uid}/>}
                />

                <Route
                    path="/app/changePassword"
                    component={() => <ChangePassword uid={this.state.uid}/>}
                />

                <Route
                    path="/app/jobs/:job_code"
                    component={(props) => <JobDetails {...props} targettedJob={this.state.targetJob} uid={this.state.uid} />}
                />

                <Route
                    path="/app/jobs/:job_code/:job_title"
                    component={(props) => <JobDetails {...props} targettedJob={this.state.targetJob} uid={this.state.uid} />}
                />

                <Route
                    path="/app/job/:job_code/:old_name"
                    component={(props) => <JobDetails {...props} targettedJob={this.state.targetJob} uid={this.state.uid} />}
                />

                <Route
                    path="/app/addJob"
                    component={() => <AddEditJob uid={this.state.uid}/>}
                />

                <Route
                    path="/app/editJob"
                    component={() => <AddEditJob uid={this.state.uid}
                                                 targettedJob={this.state.targetJob}/>}
                />

                <Route
                    path="/app/dashboard"
                    component={() => <Dashboard uid={this.state.uid}
                                                editTargetJob={this.getTargetJob}
                                                viewTargetJob={this.viewTargetJob}
                                                viewTargetJobWithName = {this.viewTargetJobWithName}
                                                />}
                />

                <Route
                    path="/app/editProfile"
                    component={() => <CompanyProfile uid={this.state.uid}
                                                     updateProfile={this.updateProfile}/>}
                />

                {/* Should not be accessed by direct url */}
                <Route
                    path="/app/submissionConfirmation"
                    component={() => <PostConfirmation uid={this.state.uid}/>}
                />

                <Route
                    path="/app/applicationConfirmation/:job_title"
                    component={(props) => <ApplicationConfirmation {...props}/>}
                />
                

                <Route
                    path="/app/about"
                    component={() => <About/>}
                />

                <Route
                    path="/app/contactUs"
                    component={() => <Contact/>}
                />


                {/* Should not be accessed by direct url */}
                <Route
                    path="/app/payment/success"
                    component={() => <Success/>}
                />


                {/* Should not be accessed by direct url */}
                <Route
                    path="/app/payment/cancel"
                    component={() => <Cancel/>}
                />

                <Route
                    path="/app/payment"
                    component={() => <PaymentPage/>}
                />

                <Route
                    path="/app/404"
                    component={() => <PageNotFound/>}
                />


                {/* TEMPORARY ROUTERS*/}

                
                {/* Should not be accessed by direct url */}
                <Route
                    path="/app/career-services/submissionConfirmation"
                    component={() => <CareerServicesConfirmation/>}
                />

                <Route
                    path="/app/career-services"
                    component={() => <CareerServices viewCareerServicesDetails={this.viewCareerServicesDetails}/>}
                />

                <Route
                    path="/app/career-services/:title"
                    component={(props) => <CareerServicesItemDetails careerServicesId={this.state.careerServicesId} {...props}/>}
                />



                <Route path="*">
                    <IndexRedirect to="/app/404"/>
                </Route>
            </div>
        );
    }

    componentDidMount(){
        if (process.env.NODE_ENV === "production") {
            ReactGA.initialize("UA-137886335-2");
            console.log("GA enabled! ")
        }
        
        this.checkSession();
    }


    checkSession() {
        const getSessionHTTPObj = {
            url: 'identity/check_session',
            method: "GET",
        };

        httpHelper(getSessionHTTPObj, this.sessionSuccess, this.sessionFailure);
    }

    sessionSuccess(resp) {
        if (resp.data.is_logged_in) {
            this.setState({
                uid: resp.data.session.account_id,
                isLoggedIn: true,
            }, () => {
                sessionStorage.setItem('client_uid', JSON.stringify(this.state.uid));
                sessionStorage.setItem('isLoggedIn', JSON.stringify(this.state.isLoggedIn));
                this.loadLogo();
                this.getProfile();

            });
        } else {

        }
    }
    sessionFailure(){

    }

    //used in Login. handled at parent component to set session and login state.
    loginSuccess(account_id, emailJobCode) {
        this.setState({
            uid: account_id,
            isLoggedIn: true,
        }, () => {
            sessionStorage.setItem('client_uid', JSON.stringify(this.state.uid));
            sessionStorage.setItem('isLoggedIn', JSON.stringify(this.state.isLoggedIn));
            this.loadLogo();
            this.getProfile();
            if (emailJobCode) {
                browserHistory.push('/app/jobs/' + emailJobCode);
            } else {
                browserHistory.push('/app/dashboard');
            }
         
        });
    }
    
    //used in companyProfile. handled at parent component to load logo.
    updateProfile() {
        this.loadLogo();
        browserHistory.push('/app/dashboard');
        //window.location.reload(false);
    }

    loadLogo() {
        const logoUrl = this.state.uid + "_logo.png";
        const data = {
            logoUrl,
        };

        const getLogoHTTPObj = {
            url: '/static/logos/' + logoUrl,
            method: "GET",
        };

        httpHelper(getLogoHTTPObj, this.logoSuccess, this.logoFailure);
    }

    logoSuccess(resp){
        const environment = returnEnvironment();
        if(resp.status == 200) {
            sessionStorage.setItem('logoUrl', JSON.stringify(environment + '/static/logos/' + this.state.uid + '_logo.png'));
            this.setState({
                logoUrl: environment + '/static/logos/' + this.state.uid + '_logo.png',
            })
 
        } else {
            sessionStorage.removeItem('logoUrl');
            this.setState({
                logoUrl: defaultLogo,
            }) 
        }
    }

    logoFailure(error){
        console.log("LOGO ERROR")
        console.log(error);
    }

    getProfile(){
        const uid = this.state.uid;
        let url = `/accounts/${uid}`;
        const companyObj = {
            method: 'GET',
            url: url,
        };

        httpHelper(companyObj, this.loadProfileSuccess, this.loadProfileFailure);
    }

    loadProfileSuccess(resp){
        if (resp.status === 200) {
            const profile = resp.data;

            this.setState({
                employer_first_name: profile.first_name || "",
            });

        } else {
            //this.requestFailure(resp.data.loginMessage);
        }
    }

    loadProfileFailure(error){
        console.log(error)
    }

    // used in JobList and Dashboard. handles function to edit target job.
    getTargetJob(job) {
        this.setState({targetJob: job}, () => {
            browserHistory.push('/app/editJob');
        });
    }

    // used in jobListItem. handle viewing of target job.
    viewTargetJob(job, job_code) {
        this.setState({ targetJob: job }, () => {
            browserHistory.push(`/app/jobs/${job_code}`);
        });
    }

    viewTargetJobWithName(job, job_code,job_title) {
        this.setState({ targetJob: job }, () => {
            browserHistory.push(`/app/jobs/${job_code}/${job_title}`);
        });
    }

    //temp for career services.
    viewCareerServicesDetails(careerServicesId, title){
        this.setState({ careerServicesId: careerServicesId }, () => {
            browserHistory.push(`/app/career-services/${title}`);
        });
    }


    //mobile optimisation drawer handling.
    handleDrawerOpen() {
        this.setState({
            openDrawer: true,
        });
    }

    savePageNo(pageNo){
        this.setState({
            currentPageNo : pageNo,
        })
    }

    //mobile optimisation drawer handling.
    handleDrawerClose() {
        this.setState({
            openDrawer: false,
        });
    }

    //global theme controller for Material Ui CSS.
    createTheme() {
        return createMuiTheme({
            palette: {
                type: 'light',
            },

            typography: {
                fontFamily: 'Roboto, sans-serif',
                h3: {
                    '@media (max-width:600px)': {
                        fontSize: '2.3rem',
                    },
                },
                h4: {
                    fontSize: "1.8rem",
                    '@media (max-width:600px)': {
                        fontSize: '1.5rem',
                    },
                },

                //wip
                h5: {
                    //default is 1.5rem.
                    '@media (max-width:600px)': {
                        fontSize: '1.2rem',
                    },
                },

                h6: {
                    //used by Add job header, req/res/benefits, news, job title 
                    //default is 1.25rem.
                    '@media (max-width:600px)': {
                        fontSize: '1.1rem',
                    },
                },

                subtitle1: {
                    //default is 1rem.
                    '@media (max-width:600px)': {
                        fontSize: '0.9rem',
                    },
                },

                body1: {
                    //default is 0.875 rem.
                    // '@media (max-width:600px)': {
                    //     fontSize: '0.7rem',
                    // },
                },

                body2: {
                    fontSize: "0.8rem",
    
                    '@media (max-width:600px)': {
                        fontSize: '0.7rem',
                    },

                    '@media (max-width:450px)': {
                        fontSize: '0.6rem',
                    },
                },


                caption: {
                    //default is 0.75 rem.
                    '@media (max-width:600px)': {
                        fontSize: '0.7rem',
                    },
                },

            },

        });
    }

    routerUpdate() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        console.log("GA: viewing page: " + window.location.pathname)
        setTimeout(window.scrollTo(0, 0),100);
    }

    render() {
        const environment = returnEnvironment();
        console.log("Environment: " + environment);
        return (
            <Suspense fallback={
                <Spinner
                    //className={classes.spinner}
                    name="chasing-dots"
                    fadeIn="none"
                    color="rgb(3,123,230)"
                    style={{
                        marginTop: "25%",
                        marginLeft: '50%',
                        width: "50px",
                        height: "50px",
                    }}
                />
            }>
                
                <MuiThemeProvider theme={this.createTheme()}>
                    <CssBaseline>

                        <PortalHeader isLoggedIn={this.state.isLoggedIn} uid={this.state.uid} logoUrl={this.state.logoUrl} employer_name={this.state.employer_first_name}/>

                        
                        <Router onUpdate={this.routerUpdate} history={browserHistory}>
                                {this.routes}                      
                        </Router>
                        <PortalFooter/>
                    </CssBaseline>
                </MuiThemeProvider>
            </Suspense>
        );
    }
}

render(<JobPortal/>, document.getElementById('root'));
