import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory} from 'react-router';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
//import companyLogo from '../../../worksider-logo.png';
import companyLogo from '../../../worksider_black.png';
import PortalStyle from '../../../assets/style/portalStyle.js';
import '../../../assets/style/portalStyle'
import httpHelper from '../../helpers/httpHelper';

class PortalHeader extends Component {
    constructor(props) {
        super(props);
        const savedLogin = JSON.parse(sessionStorage.getItem('isLoggedIn'));
        const savedClient = JSON.parse(sessionStorage.getItem('client_uid'));
        const savedUsername = JSON.parse(sessionStorage.getItem('username'));

        this.state = {
            nightMode: false,
            targettedJob: null,
            openDrawer: false,

            //the variables below may not be needed
            user: savedUsername ? savedUsername : "",
            uid: savedClient ? savedClient : 0, 
            isLoggedIn: savedLogin ? savedLogin : false,
        };

        this.allowPostJob = this.allowPostJob.bind(this);
        this.denyPostJob = this.denyPostJob.bind(this);
        this.goHome = this.goHome.bind(this);
        this.logout = this.logout.bind(this);
        this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
        this.handleDrawerClose = this.handleDrawerClose.bind(this);
        //this.onLogoError = this.onLogoError.bind(this);
        this.goDashboard = this.goDashboard.bind(this);
        this.logoutSuccess = this.logoutSuccess.bind(this);
        this.logoutFailure = this.logoutFailure.bind(this)

    }

    componentDidMount(){
        //this.loadLogo();
    }

    allowPostJob() {
        browserHistory.push('/app/addJob');
    }

    denyPostJob() {
        alert('Please log in to create a job listing!');
        browserHistory.push('/app/login');
    }

    logout() {
        const logoutHTTPObj = {
            url: 'identity/logout',
        };

        httpHelper(logoutHTTPObj, this.logoutSuccess, this.logoutFailure);
    }

    logoutSuccess(resp) {
        if(resp.status == 200) {
            sessionStorage.removeItem('isLoggedIn');
            sessionStorage.removeItem('client_uid');
            sessionStorage.removeItem('username');
            sessionStorage.removeItem('client_uid');
            sessionStorage.removeItem('logoUrl');
    
            browserHistory.push('/app');
            location.reload();
        } else { 
            console.log(resp)
           alert("Error during login!")
        }

    }

    logoutFailure(error) {
        console.log(error)
        alert("Error during logout!")
    }

    goHome() {
        sessionStorage.removeItem('targetJob');
        location.href = 'https://www.worksider.com/';
    }

    goDashboard() {
        browserHistory.push("/app/dashboard");
        //location.reload();
    }

    handleDrawerOpen() {
        this.setState({
            openDrawer: true,
        });
    }

    handleDrawerClose() {
        this.setState({
            openDrawer: false,
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <header
                position="static"
                style={{
                    backgroundColor: "rgb(250, 250, 250)",
                    //backgroundColor: 'rgb(3,123,230)',
                    boxShadow: 'none',
                    paddingTop: '5px',
                    paddingLeft: '5%',
                    paddingRight: '5%',
                    borderBottom: 'solid darkgrey 1px',
                    position: "fixed",
                    top: 0,
                    width: "100%",
                    zIndex: "2",
                }}
            >
                <Toolbar>
                    <Grid container direction="row" justify="flex-start" style={{ alignItems: 'center' }}>
                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                            <div style={{
                                display: 'inline-flex', cursor: 'pointer', maxWidth: '260px', minWidth: '150px', height: "auto", maxHeight: "36px",
                            }}
                            >
                                <CardMedia
                                    component="img"
                                    height="auto"
                                    alt="IMAGE"
                                    image={companyLogo}
                                    onClick={this.goHome}
                                />
                            </div>
                        </Grid>

                        <Grid
                            item
                            xs={4}
                            sm={4}
                            md={5}
                            lg={5}
                            xl={5}
                            style={{ display: 'inline-flex' }}
                        >

                            <div className={classes.fullMenu} style={{ display: 'none' }}>
                                <div className={classes.banner_underline} style={{marginLeft: "10px"}}>
                                    <Typography
                                        variant="body2"
                                        className={classes.banner_text}
                                        onClick={() => {
                                            browserHistory.push('/app');
                                        }}
                                    >
                                        JOBS
                                    </Typography>
                                </div>
            
                                { this.props.isLoggedIn
                                && (
                                    <div className={classes.banner_underline}>
                                         <Typography
                                            variant="body2"
                                            className={classes.banner_text}
                                            style={{ marginLeft: '20px'}}
                                            onClick={() => {
                                                if (this.props.isLoggedIn) {
                                                    browserHistory.push('/app/dashboard');
                                                } else {
                                                    browserHistory.push('/app/login');
                                                }
                                            }}
                                        >
                                            DASHBOARD
                                        </Typography>
                                    </div>
                                )}
                                
                                <div className={classes.banner_underline}>
                                    <Typography
                                        variant="body2"
                                        className={classes.banner_text}
                                        style={{ marginLeft: '20px', color:"rgb(3,123,230)"}}
                                        onClick={() => {
                                            browserHistory.push('/app/career-services');
                                        }}
                                    >
                                        CAREER SERVICES <sup style={{
                                            color:"red", 
                                            fontWeight:"600px", 
                                            fontSize:"7px", 
                                            verticalAlign: "bottom",
                                            position: "relative",
                                            top: "-0.4em"
                                            }}>
                                                NEW!</sup>
                                    </Typography>
                                </div>

                                {/* <div className={classes.banner_underline}>
                                    <Typography
                                        variant="body2"
                                        className={classes.banner_text}
                                        style={{ marginLeft: '20px', color:"green"}}
                                        onClick={() => {
                                            browserHistory.push('/app/career-services');
                                        }}
                                    >
                                        CONTENT <sup style={{color:"red", fontWeight:"600px", fontSize:"7px"}}>NEW!</sup>
                                    </Typography>
                                </div> */}
                                
                                {/* <div className={classes.banner_underline}>
                                    <Typography
                                        variant="body2"
                                        className={classes.banner_text}
                                        style={{ marginLeft: '15px'}}
                                        onClick={() => {
                                            browserHistory.push('/app/contactUs');
                                        }}
                                    >
                                        CONTACT
                                    </Typography>
                                </div> */}
                                                                    
                        </div>

                        </Grid>

                        <Grid
                            item
                            xs={6}
                            sm={6}
                            md={5}
                            lg={5}
                            xl={5}
                            align="right"
                            style={{ display: 'inline-flex', alignItems: 'center'}}
                        >

                            <div className={classes.fullMenu} style={{ display: 'none' }}>
                                { this.props.isLoggedIn
                                &&
                                    <Typography variant="body1" style={{ marginLeft: 'auto', marginRight: '7px', color: "black", maxWidth: "180px", fontWeight: "600"}}>
                                        {/* <Typography variant="caption" style={{color: "white"}}>
                                            Hello,
                                        </Typography> */}
                                        {this.props.employer_name}
                                    </Typography>
                                }

                                { this.props.isLoggedIn
                                && 
                                 <Avatar src={this.props.logoUrl} style={{marginRight: '7px', cursor: "pointer", border: "1px solid darkgrey", }} onClick={this.goDashboard}>
                                 </Avatar>
                                }

                                { this.props.isLoggedIn
                                && (
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            type="button"
                                            onClick={this.allowPostJob}
                                            className={classes.banner_btn}
                                            style={{
                                                border: '0',
                                                backgroundColor: 'RGB(0, 211, 99)',
                                            }}
                                        >
                                            POST A JOB
                                        </Button>
                                    )}
                                
                                {!this.props.isLoggedIn  
                                    && (
                                        
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            type="button"
                                            onClick={this.denyPostJob}
                                            className={classes.banner_btn}
                                            style={{
                                                border: '0',
                                                backgroundColor: 'RGB(0, 211, 99)',
                                                marginLeft: 'auto',
                                            }}
                                        >
                                            POST A JOB
                                        </Button>
                                        
                                )}

                                { this.props.isLoggedIn
                                    && (
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            type="button"
                                            onClick={this.logout}
                                            className={classes.banner_btn_logout}
                                        >
                                            LOGOUT
                                        </Button>
                                    )}

                                { !this.props.isLoggedIn
                                    && (
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            type="button"
                                            className={classes.banner_btn_login}
                                            onClick={() => { browserHistory.push('/app/login'); }}
                                        >
                                            EMPLOYER
                                        </Button>
                                    )}
        
                            </div>

                            
                            <div className={classes.menu_icon} style={{ marginLeft: 'auto', display: 'none', color: 'RGB(3,123,230)' }}>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="end"
                                    onClick={this.handleDrawerOpen}

                                >
                                    <MenuIcon color="inherit" />
                                </IconButton>
                            </div>
                            

                        </Grid>

                        <Drawer
                            className={classes.drawer}
                            variant="persistent"
                            anchor="right"
                            open={this.state.openDrawer}
                            classes={{ paper: classes.drawer_paper }}
                        >
                            <div style={{ padding: '10px' }}>
                                <div style={{ color: 'white' }}>
                                    <IconButton onClick={this.handleDrawerClose} color="inherit">
                                        <ChevronRightIcon color="inherit" />
                                    </IconButton>
                                </div>

                                <div style={{ textAlign: 'center', marginLeft:"30px", marginRight:"30px" }} onClick={this.handleDrawerClose}>
                                   <div style={{display: "inline-flex", paddingBottom: "10px", borderBottom: "2px solid white", alignItems: "center", }}>
                                    { this.props.isLoggedIn
                                        &&
                                            <Typography variant="subtitle1" style={{ marginLeft: 'auto', marginRight: '7px', color: "white", maxWidth: "180px", fontWeight: "600"}}>
                                                {/* <Typography variant="body1" style={{color: "white"}}>
                                                    Hello,
                                                </Typography> */}
                                                {this.props.employer_name}
                                            </Typography>
                                        }

                                        { this.props.isLoggedIn
                                        && 
                                        <Avatar src={this.props.logoUrl} style={{marginRight: '7px', cursor: "pointer" }} onClick={this.goDashboard}>
                                        </Avatar>
                                        }
                                    </div>
                                    
                                    <div className={classes.banner_underline_drawer} style={{paddingTop: "13px"}}>
                                        <Typography
                                            variant="h6"
                                            className={classes.banner_text_drawer}
                                            style={{
                                                marginBottom: '7px', color: 'white', fontWeight: '500', cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                browserHistory.push('/app');
                                            }}
                                        >
                                            JOBS
                                        </Typography>
                                    </div>
                

                                    { this.props.isLoggedIn
                                    && (
                                        <div className={classes.banner_underline_drawer}>
                                        <Typography
                                            variant="h6"
                                            className={classes.banner_text_drawer}
                                            style={{
                                                marginBottom: '7px', color: 'white', fontWeight: '500', cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                if (this.props.isLoggedIn) {
                                                    browserHistory.push('/app/dashboard');
                                                } else {
                                                    browserHistory.push('/app/login');
                                                }
                                            }}
                                        >
                                            DASHBOARD
                                        </Typography>
                                        </div>
                                    )}
{/* 
                                    <div className={classes.banner_underline_drawer}>
                                            <Typography
                                            variant="h6"
                                            className={classes.banner_text_drawer}
                                            style={{
                                                marginBottom: '7px', color: 'white', fontWeight: '500', cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                browserHistory.push('/app/career-services');
                                            }}
                                        >
                                            CAREER SERVICES <sup style={{color:"yellow", fontWeight:"600px", fontSize:"9px"}}>NEW!</sup>
                                        </Typography>
                                        </div> */}

                                    <div className={classes.banner_underline_drawer} style={{paddingBottom: "8px", borderBottom: "2px solid white"}}>
                                        <Typography
                                            variant="h6"
                                            className={classes.banner_text_drawer}
                                            style={{
                                                marginBottom: '7px', color: 'white', fontWeight: '500', cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                browserHistory.push('/app/career-services');
                                            }}
                                        >
                                            CAREER SERVICES <sup style={{color:"yellow", fontWeight:"600px", fontSize:"9px"}}>NEW!</sup>
                                        </Typography>
                                        {/* <Typography
                                            variant="h6"
                                            className={classes.banner_text_drawer}
                                            style={{
                                                marginBottom: '7px', color: 'white', fontWeight: '500', cursor: 'pointer',
                                            }}
                                        >
                                            CONTENT <sup style={{color:"yellow", fontWeight:"600px", fontSize:"9px"}}>NEW!</sup>
                                        </Typography> */}
                                    </div>
{/* 
                                    <div className={classes.banner_underline} style={{paddingBottom: "10px", borderBottom: "2px solid white" }}>
                                        <Typography
                                            variant="h6"
                                            className={classes.banner_text_drawer}
                                            style={{
                                                marginBottom: '7px', color: 'white', fontWeight: '500', cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                browserHistory.push('/app/contactUs');
                                            }}
                                        >
                                            CONTACT
                                        </Typography>
                                    </div> */}

                                    <div className={classes.banner_underline_drawer}>
                                    <Typography
                                        variant="h6"
                                        className={classes.banner_text_drawer}
                                        style={{
                                            marginBottom: '7px', color: 'white', fontWeight: '500', cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            console.log('clicked');
                                        }}
                                    >
                                       
                                    </Typography>
                                    </div>
                                </div>

                                <div style={{ textAlign: 'center', marginLeft:"30px", marginRight:"30px" }}onClick={this.handleDrawerClose}>
                                    { this.props.isLoggedIn
                                        ? (
                                            <div className={classes.banner_underline_drawer} style={{paddingTop: "9px"}}>
                                            <Typography
                                                variant="h6"
                                                className={classes.banner_text_drawer}
                                                style={{
                                                    marginBottom: '7px', color: 'white', fontWeight: '500', cursor: 'pointer',
                                                }}
                                                onClick={this.allowPostJob}
                                            >
                                                POST A JOB
                                            </Typography>
                                            </div>
                                        )
                                        : (
                                            <div className={classes.banner_underline_drawer} style={{paddingTop: "9px"}}>
                                            <Typography
                                                variant="h6"
                                                className={classes.banner_text_drawer}
                                                style={{
                                                    marginBottom: '7px', color: 'white', fontWeight: '500', cursor: 'pointer',
                                                }}
                                                onClick={this.denyPostJob}
                                            >
                                                POST A JOB
                                            </Typography>
                                            </div>
                                        )}

                                    { this.props.isLoggedIn
                                        ? (
                                            <div className={classes.banner_underline_drawer}>
                                            <Typography
                                                variant="h6"
                                                className={classes.banner_text_drawer}
                                                style={{
                                                    marginBottom: '7px', color: 'white', fontWeight: '500', cursor: 'pointer',
                                                    border: '1px white solid',
                                                }}
                                                onClick={this.logout}          
                                            >
                                                LOGOUT
                                            </Typography>
                                            </div>
                                        )
                                        : (
                                            <div className={classes.banner_underline_drawer}>
                                            <Typography
                                                variant="h6"
                                                className={classes.banner_text_drawer}
                                                style={{
                                                    marginBottom: '7px', color: 'white', fontWeight: '500', cursor: 'pointer',
                                                    border: '1px white solid',
                                                }}
                                                onClick={() => { browserHistory.push('/app/login'); }}
                                            >
                                                EMPLOYER
                                            </Typography>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </Drawer>
                    </Grid>
                </Toolbar>
            </header>
        );
    }
}

PortalHeader.propTypes = {
    classes: PropTypes.object.isRequired,
};

PortalHeader.defaultProps = {};

export default withStyles(PortalStyle)(PortalHeader);

