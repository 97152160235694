export default function returnEnvironment() {
    const setting = process.env.NODE_ENV;

    if (setting === "development") {
        return "http://localhost:3001"
    }

    if (setting === "staging") {
        return "http://arlify.com:5000/api"
    }

    if (setting === "production") {
        return "https://worksider.com/api"
    }
}



